@charset "utf-8";

// Our variables
$base-font-family: "Crimson Text", "Times", serif;
$base-font-size:   1.2em;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$header-font-family: "Vollkorn";

$spacing-unit:     30px;

$text-color: #555555;
$background-color: #fdfdfd;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;

// Minima also includes a mixin for defining media queries.
// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }

// Import partials from the `minima` theme.
@import "minima";

figure.framed {
	border: 1px solid #efefef;
	background-color: #f9f9f9;
}

figcaption {
	margin: 10px;
	font-family: "Crimson Text";
	font-weight: 600;
	font-size: 0.8em;
}

h1.post-title, .site-title {
	font-size: 2.5em;
	font-family: $header-font-family;
	font-weight: 900;
	font-decoration: none;
}

.site-title {
	font-size: 1.5em;
}

.post-content h2 {
	font-size: 1.8em;
	font-family: $header-font-family;
	font-weight: 900;
	font-decoration: none;
}

h2.project-title, h3, h1 {
	font-family: $header-font-family;
	font-weight: 900;
	font-decoration: none;
	font-size: 1.1em;
	padding-bottom: 0px;
	margin-bottom: 0px;
}

header.site-header {
	height: 360px;
	background-image: url("/assets/images/timkettering/teton-range.jpg");
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: center center; 
}

header .alpha {
	max-width: 10000px;
	background: rgba(255, 255, 255, 0.75);
}

.video {
	padding: 2px;
	border: 1px solid gray;
	background-color: silver;
	margin-bottom: 20px;
	box-shadow: 0px 1px 5px grey;
}

.info_slug {
	border: 1px solid #557d9a;
	padding: 5px 20px;
	background-color: #e0e4ee;
	margin-top: 20px;
	margin-bottom: 20px;
	border-radius: 3px;
}